var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "commodity-list-container" },
    [
      _c("search", {
        attrs: { text: _vm.searchForm.searchFiled },
        on: {
          "update:text": function ($event) {
            return _vm.$set(_vm.searchForm, "searchFiled", $event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "commodity-list" },
        [
          _c(
            "div",
            { staticClass: "breadcrumb-container" },
            [
              _c(
                "el-breadcrumb",
                { attrs: { "separator-class": "el-icon-arrow-right" } },
                [
                  _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                    _vm._v("首页"),
                  ]),
                  !_vm.selectArr[0]
                    ? _c("el-breadcrumb-item", [
                        _vm._v(_vm._s(_vm.searchForm.searchFiled)),
                      ])
                    : _vm._e(),
                  _vm.selectArr[0]
                    ? _c(
                        "el-breadcrumb-item",
                        [
                          _c(
                            "el-dropdown",
                            { attrs: { placement: "bottom-start" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { cursor: "pointer" } },
                                [_vm._v(_vm._s(_vm.selectArr[0].categoryNm))]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "childList",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  _vm.productCategory.childTree,
                                  function (item, index) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: index,
                                        staticClass: "childListson",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                path: "/commodity-list",
                                                query: {
                                                  categoryId: item.categoryId,
                                                  categoryCode:
                                                    item.categoryCode,
                                                },
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.categoryNm))]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectArr[1]
                    ? _c(
                        "el-breadcrumb-item",
                        [
                          _c(
                            "el-dropdown",
                            { attrs: { placement: "bottom-start" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { cursor: "pointer" } },
                                [_vm._v(_vm._s(_vm.selectArr[1].categoryNm))]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "childList",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(_vm.secondArray, function (item, index) {
                                  return _c(
                                    "el-dropdown-item",
                                    { key: index, staticClass: "childListson" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              path: "/commodity-list",
                                              query: {
                                                categoryId: item.categoryId,
                                                categoryCode: item.categoryCode,
                                              },
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.categoryNm))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectArr[2]
                    ? _c("el-breadcrumb-item", [
                        _vm._v(_vm._s(_vm.selectArr[2].categoryNm)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.license.isAllLicenseExpired === "Y"
            ? _c(
                "div",
                { staticClass: "aptitudes" },
                [
                  _vm._v(
                    "温馨提示：" +
                      _vm._s(
                        _vm.userType !== "SALESMAN"
                          ? "您有" +
                              _vm.license.failureSum +
                              "张证照已过期,请联系业务员更换,以免影响您的正常采购"
                          : "该客户有" +
                              _vm.license.failureSum +
                              "张证照已过期，以免影响正常采购！"
                      )
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v("查看详情")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("swiper", {
            staticStyle: { "margin-top": "-32px" },
            attrs: { appoint: true },
          }),
          _c(
            "category-search",
            {
              ref: "categorySearch",
              attrs: {
                "view-model": _vm.viewModel,
                manufacture: _vm.manufacture,
                searchForm: _vm.searchForm,
                searchs: _vm.searchs,
              },
              on: {
                "change-page": _vm.handleChangViewModel,
                select: _vm.select,
                secondCategory: _vm.secondCategory,
                "update:searchForm": function ($event) {
                  _vm.searchForm = $event
                },
                "update:search-form": function ($event) {
                  _vm.searchForm = $event
                },
                secondFilter: _vm.secondFilter,
                search: _vm.queryData,
              },
            },
            [
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "search-input el-input el-input--large el-input-group el-input-group--append",
                    staticStyle: {
                      width: "240px",
                      height: "32px",
                      margin: "6px 20px 0 10px",
                    },
                    attrs: { "data-v-7b59048f": "" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchForm.searchFiled,
                          expression: "searchForm.searchFiled",
                        },
                      ],
                      staticClass: "el-input__inner",
                      staticStyle: { height: "32px" },
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        placeholder: "在结果中搜索",
                      },
                      domProps: { value: _vm.searchForm.searchFiled },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchForm,
                            "searchFiled",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "el-input-group__append" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "el-button search-button el-button--mini",
                          staticStyle: { height: "32px" },
                          attrs: { "data-v-7b59048f": "", type: "button" },
                          on: { click: _vm.queryData },
                        },
                        [_c("i", { staticClass: "el-icon-search" })]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "kind-paging" },
                  [
                    _vm._v(" 找到品种" + _vm._s(_vm.total) + "个 "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentPage) +
                          "/" +
                          _vm._s(Math.ceil(_vm.total / _vm.pageSize))
                      ),
                    ]),
                    _c("el-pagination", {
                      attrs: {
                        small: true,
                        background: "",
                        layout: "prev,next",
                        total: _vm.total,
                        "current-page": _vm.currentPage,
                        "page-size": _vm.pageSize,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function ($event) {
                          _vm.currentPage = $event
                        },
                        "update:pageSize": function ($event) {
                          _vm.pageSize = $event
                        },
                        "update:page-size": function ($event) {
                          _vm.pageSize = $event
                        },
                        "prev-click": _vm.handlePrev,
                        "next-click": _vm.handlePrev,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _vm.viewModel === "ListBoard"
            ? _c("list-board", {
                attrs: {
                  "table-data": _vm.tableData,
                  isLoading: _vm.isLoadingList,
                  "sale-date-type": _vm.saleDateType,
                  sorts: _vm.sorts,
                },
                on: { sortOder: _vm.sortOder },
              })
            : _vm._e(),
          _vm.viewModel === "PhotoBoard"
            ? _c("photo-board", {
                attrs: {
                  "table-data": _vm.tableData,
                  isLoading: _vm.isLoadingList,
                },
              })
            : _vm._e(),
          _vm.tableData.length
            ? _c("pagination", {
                attrs: {
                  total: _vm.total,
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                },
                on: {
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  pagination: _vm.handlePagination,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("tool-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }