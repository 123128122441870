import { render, staticRenderFns } from "./order-express.vue?vue&type=template&id=fb500166&scoped=true"
import script from "./order-express.vue?vue&type=script&lang=js"
export * from "./order-express.vue?vue&type=script&lang=js"
import style0 from "./order-express.vue?vue&type=style&index=0&id=fb500166&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb500166",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/jobs/pre-pc-web/workspace@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb500166')) {
      api.createRecord('fb500166', component.options)
    } else {
      api.reload('fb500166', component.options)
    }
    module.hot.accept("./order-express.vue?vue&type=template&id=fb500166&scoped=true", function () {
      api.rerender('fb500166', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/member-center/order-manage/refunds-yw/components/order-express.vue"
export default component.exports