var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "category-container" }, [
    _vm.firstSelectItem ||
    _vm.secondSelectItem ||
    _vm.thirdSelectItem ||
    _vm.selectFacture ||
    _vm.checkList.length
      ? _c("div", { staticClass: "first-grade grade-item" }, [
          _c("div", { staticClass: "grade-label" }, [_vm._v(" 已选择： ")]),
          _c(
            "ul",
            { staticClass: "grade-values" },
            [
              _vm.selectItem
                ? _c(
                    "el-tag",
                    {
                      attrs: { size: "small", closable: "" },
                      on: {
                        close: () => {
                          _vm.firstSelectItem = ""
                          _vm.secondSelectItem = ""
                          _vm.thirdSelectItem = ""
                          _vm.handleSearch()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.selectItem) + " ")]
                  )
                : _vm._e(),
              _vm.selectFacture
                ? _c(
                    "el-tag",
                    {
                      attrs: { size: "small", closable: "" },
                      on: {
                        close: () => {
                          _vm.selectFacture = ""
                          _vm.handleSearch("nowCategory")
                        },
                      },
                    },
                    [_vm._v(" 厂家：" + _vm._s(_vm.selectFacture) + " ")]
                  )
                : _vm._e(),
              _vm.checkList.length
                ? _vm._l(_vm.checkList, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { size: "small", closable: "" },
                        on: {
                          close: () => {
                            _vm.checkList.splice(_vm.checkList.indexOf(tag), 1)
                            _vm.handleSearch("nowCategory")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(tag === "RX" ? "处方RX" : "") +
                            " " +
                            _vm._s(tag === "A_OTC" ? "甲OTC" : "") +
                            " " +
                            _vm._s(tag === "B_OTC" ? "乙OTC" : "") +
                            " " +
                            _vm._s(tag === "OTHER" ? "非药品" : "") +
                            " "
                        ),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "first-grade grade-item" }, [
      _c("div", { staticClass: "grade-label" }, [_vm._v(" 一级类目： ")]),
      _c(
        "ul",
        { staticClass: "grade-values" },
        [
          _vm.productCategory
            ? _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.firstSelectItem,
                    callback: function ($$v) {
                      _vm.firstSelectItem = $$v
                    },
                    expression: "firstSelectItem",
                  },
                },
                _vm._l(_vm.productCategory.childTree, function (item, index) {
                  return _c(
                    "el-radio",
                    {
                      key: index,
                      attrs: { label: item },
                      on: {
                        change: () => {
                          _vm.thirdSelectItem = ""
                          _vm.secondSelectItem = ""
                          _vm.handleSelectItem(item.categoryCode)
                          _vm.handleSearch(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.categoryNm) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "selection-item" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                _vm.isExpand0 = !_vm.isExpand0
              },
            },
          },
          [
            _c("i", {
              staticClass: "toggle-arrow",
              class: _vm.isExpand0 ? "el-icon-arrow-up" : "el-icon-arrow-down",
            }),
          ]
        ),
      ]),
    ]),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpand0,
            expression: "isExpand0",
          },
        ],
      },
      [
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.secondCategory.length,
                expression: "secondCategory.length",
              },
            ],
            staticClass: "grade-item",
          },
          [
            _c("div", { staticClass: "grade-label" }, [_vm._v(" 二级类目： ")]),
            _c(
              "ul",
              {
                staticClass: "grade-values",
                class: _vm.isExpand1 ? "" : "small",
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.secondSelectItem,
                      callback: function ($$v) {
                        _vm.secondSelectItem = $$v
                      },
                      expression: "secondSelectItem",
                    },
                  },
                  _vm._l(_vm.secondCategory, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        attrs: { label: item },
                        on: {
                          change: () => {
                            _vm.thirdSelectItem = ""
                            _vm.handleSelectItem(item.categoryCode)
                            _vm.handleSearch(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.categoryNm) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "selection-item" }, [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.isExpand1 = !_vm.isExpand1
                    },
                  },
                },
                [
                  _vm._v(_vm._s(_vm.isExpand1 ? "收起" : "更多") + " "),
                  _c("i", {
                    staticClass: "el-icon--right",
                    class: _vm.isExpand1
                      ? "el-icon-arrow-up"
                      : "el-icon-arrow-down",
                  }),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.thirdCategory.length,
                expression: "thirdCategory.length",
              },
            ],
            staticClass: "grade-item",
          },
          [
            _c("div", { staticClass: "grade-label" }, [_vm._v(" 三级类目： ")]),
            _c(
              "ul",
              {
                staticClass: "grade-values",
                class: _vm.isExpand2 ? "" : "small",
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.thirdSelectItem,
                      callback: function ($$v) {
                        _vm.thirdSelectItem = $$v
                      },
                      expression: "thirdSelectItem",
                    },
                  },
                  _vm._l(_vm.thirdCategory, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        attrs: { label: item },
                        on: {
                          change: function ($event) {
                            return _vm.handleSearch(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.categoryNm) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "selection-item" }, [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.isExpand2 = !_vm.isExpand2
                    },
                  },
                },
                [
                  _vm._v(_vm._s(_vm.isExpand2 ? "收起" : "更多") + " "),
                  _c("i", {
                    staticClass: "el-icon--right",
                    class: _vm.isExpand2
                      ? "el-icon-arrow-up"
                      : "el-icon-arrow-down",
                  }),
                ]
              ),
            ]),
          ]
        ),
        _c("li", { staticClass: "grade-item" }, [
          _c("div", { staticClass: "grade-label" }, [_vm._v(" 生产厂家： ")]),
          _c(
            "ul",
            {
              staticClass: "grade-values",
              class: _vm.isExpand3 ? "" : "small",
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.selectFacture,
                    callback: function ($$v) {
                      _vm.selectFacture = $$v
                    },
                    expression: "selectFacture",
                  },
                },
                _vm._l(_vm.manufacture, function (item, index) {
                  return _c(
                    "el-radio",
                    {
                      key: index,
                      attrs: { label: item },
                      on: {
                        change: function ($event) {
                          return _vm.handleManufacture(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "selection-item" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.isExpand3 = !_vm.isExpand3
                  },
                },
              },
              [
                _vm._v(_vm._s(_vm.isExpand3 ? "收起" : "更多") + " "),
                _c("i", {
                  staticClass: "el-icon--right",
                  class: _vm.isExpand3
                    ? "el-icon-arrow-up"
                    : "el-icon-arrow-down",
                }),
              ]
            ),
          ]),
        ]),
        _c("li", { staticClass: "grade-item" }, [
          _c("div", { staticClass: "grade-label" }, [_vm._v(" 处方类： ")]),
          _c(
            "div",
            { staticClass: "grade-values" },
            [
              _c(
                "el-checkbox-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleSearch("nowCategory")
                    },
                  },
                  model: {
                    value: _vm.checkList,
                    callback: function ($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "RX" } }, [
                    _vm._v("处方RX"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "A_OTC" } }, [
                    _vm._v("甲OTC"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "B_OTC" } }, [
                    _vm._v("乙OTC"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "OTHER" } }, [
                    _vm._v("非药品"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        false
          ? _c("li", { staticClass: "grade-item" }, [
              _c("div", { staticClass: "grade-label" }, [_vm._v(" 活动： ")]),
              _c(
                "div",
                { staticClass: "grade-values" },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.handleSearch },
                      model: {
                        value: _vm.isDisCount,
                        callback: function ($$v) {
                          _vm.isDisCount = $$v
                        },
                        expression: "isDisCount",
                      },
                    },
                    [_vm._v("是否促销")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "table-switch" }, [
      _c("div", { staticClass: "table-switch-row" }, [
        _c(
          "ul",
          { staticClass: "sort" },
          [
            _c("li", [_vm._v("默认")]),
            _vm._l(_vm.searchs, function (item, index) {
              return _c(
                "li",
                { key: index },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeType(item.code)
                        },
                      },
                      model: {
                        value: _vm.selectionSearchs,
                        callback: function ($$v) {
                          _vm.selectionSearchs = $$v
                        },
                        expression: "selectionSearchs",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: item.code,
                            disabled:
                              (_vm.isNear && item.code === "EXP_DAY_NEAR") ||
                              (_vm.isFar && item.code === "EXP_DAY_FAR"),
                          },
                        },
                        [
                          item.code === "SALE_NUM_ONE_YEAR"
                            ? _c(
                                "span",
                                {
                                  staticClass: "drow-con",
                                  on: { mouseover: _vm.handleMouseOver },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                  _vm.isShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "sale-type",
                                          on: {
                                            mouseleave: _vm.handleMouseLeave,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { input: _vm.selectDate },
                                              model: {
                                                value: _vm.saleDateType,
                                                callback: function ($$v) {
                                                  _vm.saleDateType = $$v
                                                },
                                                expression: "saleDateType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "SALE_NUM_ALL_TIME",
                                                  },
                                                },
                                                [_vm._v("全部销量")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "SALE_NUM_ONE_YEAR",
                                                  },
                                                },
                                                [_vm._v("近一年销量")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "SALE_NUM_HALF_YEAR",
                                                  },
                                                },
                                                [_vm._v("近半年销量")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label:
                                                      "SALE_NUM_THREE_MONTH",
                                                  },
                                                },
                                                [_vm._v("近三个月销量")]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: "SALE_NUM_ONE_MONTH",
                                                  },
                                                },
                                                [_vm._v("近一个月销量")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(item.name))]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "right-iew-pattern" },
          [
            _vm._t("default"),
            _c("div", { staticClass: "view-pattern" }, [
              _c(
                "div",
                {
                  staticClass: "photo-board",
                  class: { active: _vm.viewModel === "PhotoBoard" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchView("PhotoBoard")
                    },
                  },
                },
                [_c("span"), _vm._v(" 大图 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "table-board",
                  class: { active: _vm.viewModel === "ListBoard" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSwitchView("ListBoard")
                    },
                  },
                },
                [_c("span"), _vm._v(" 列表 ")]
              ),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }