import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import utils from './utils'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import defaultSettings from '@/settings'

import '@/permission' // permission control

// import '@/tinymce/themes/silver/theme.min.css'; 

Vue.use(ElementUI);

// import {trackInit} from '@/plugins/track/index.js'

// trackInit()

import ImTablePage from '@/components/ImTablePage'
Vue.use(ImTablePage)
import ImPagination from '@/components/ImPagination'
Vue.use(ImPagination)
import ImPageModal from '@/components/ImPageModal'
Vue.use(ImPageModal)
import ImPicZoom from '@/components/ImPicZoom'
Vue.use(ImPicZoom)
import ImDrawer from '@/components/ImDrawer'
Vue.use(ImDrawer)
import ImSearchPad from '@/components/ImSearchPad'
Vue.use(ImSearchPad)
import ImSearchPadItem from '@/components/ImSearchPadItem'
Vue.use(ImSearchPadItem)
import ImToolBar from '@/components/ImToolBar'
Vue.use(ImToolBar)
import ImConfirmPopup from '@/components/ImConfirmPopup'
Vue.use(ImConfirmPopup)
Vue.config.productionTip = false;
Vue.prototype.$util = utils;
Vue.prototype.defaultSettings = defaultSettings
Vue.filter('getGoodsName',utils.getGoodsName)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
