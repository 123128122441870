<template>
  <div class="member-center">
    <Search :hidden-nav="true"/>
    <div class="member-center-container">
      <el-container :class="[{moreWidth:moreWidth}, {customerWidth:customerWidth}]">
        <el-aside width="200px">
          <nav-menu
              :params="params"
              @handle-change-page="handleChangePage"
          ></nav-menu>
        </el-aside>
        <el-main>
          <router-view @handle-change-page="handleChangePage"/>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/Search/Search'
  import NavMenu from './nav-menu/nav-menu'

  export default {
    name: 'Order',
    components: { Search, NavMenu },
    data () {
      return {
        params: {
          view: 'store-order-list',
          index: '1-1'
        },
        moreWidth: false,
        customerWidth: false
      }
    },
    methods: {
      handleChangePage (data) {
        if(data.index === '5-1' || data.index === '5-2' || data.index === '5-3' || data.index === '5-4'){   // 委托配送页面要求一页展示所有数据不能左右滑动所以加宽
          this.moreWidth = true
          this.customerWidth = false
        }else if(data.index === '3-3' || data.index === '4-3' || data.index === '3-1'){  // 往来账核对要求大屏一页展示所有不要滑动
          this.moreWidth = false
          this.customerWidth = true
        }else {
          this.moreWidth = false
          this.customerWidth = false
        }
        this.params = data
        this.$emit('handle-change-page', { ...data })
      }
    }

  }
</script>

<style scoped lang="scss">
  .member-center {
    width: 100%;
    min-width: 1200px;
    background: #ffffff;

    .search-main {
      border-bottom: none;
    }

    .member-center-container {
      margin-top: 5px;
      background: #F5F5F5;
      padding-bottom: 20px;
    }

    .el-container {
      margin: 0 auto;
      width: 1200px;
      height: auto;

      .el-aside {
        background: #ffffff;
        height: auto;
        overflow: inherit;
      }

      .el-main {
        padding: 0;
        margin-left: 8px;
        background: #f5f5f5;
        border-radius: 8px 8px 0 0;
      }
    }
    .moreWidth{
      width: 1200px;
    }
    .customerWidth{
      width: 93%;
    }
  }
</style>
